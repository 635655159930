<template>
  <div id="solution-content">
    <div class="cover" :style="'background-image: url(' + selectedSolution.cover + ');'"></div>
    <Navigation/>
    <div class="solution-container">
      <div class="square animate__animated animate__fadeInLeft"></div>
      <div class="solutions-list">
        <ul class="solution-links">
          <li v-for="solution in solutions" v-bind:key="solution.id" v-bind:class="solution.id == selectedSolution.id ? 'selected':'animate__animated animate__fadeInUp'">
            <a :href="'/solution/' + solution.url" @mouseover="previewService(solution.id)">{{solution.pane_title}}</a>
          </li>
          <li class="portfolio">
            <a href="/portfolio">Portfolio &rarr;</a>
          </li>
        </ul>
      </div>
      <div class="solution-content animate__animated animate__fadeInDown animate__delay-1s">
        <div class="solution">
          <div class="solution-title">{{selectedSolution.name}}</div>
          <div class="solution-body" v-html="selectedSolution.content"></div>
        </div>
        <div class="solution-images">
          <img v-for="image of selectedSolution.images" v-bind:key="image" v-bind:src="image" alt="">
        </div>
      </div>
    </div>
    <More @next="pageMove"/>
    <MobileMore/>
  </div>
</template>

<script>
import store from '@/store'

export default {
  components: {
    Navigation: () => import('@/components/nav'),
    More: () => import('@/components/more'),
    MobileMore: () => import('@/components/mobile-more')
  },
  props: {
    url: String
  },
  computed: {
    solutions: () => store.state.solution.solutions
  },
  data: () => ({
    selectedSolution: {},
    pageIndex: 0
  }),
  mounted() {
    store.commit('SET_NAV_PAGE', 'solution')
    store.commit('SET_NAV_THEME', 'darkLogoLight')
    store.commit('SET_MORE_THEME', 'light')
    store.commit('SET_MORE_PAGES', this.solutions)
    store.commit('SET_MORE_HAS_SCROLL', false)
    this.previewSolution(this.url)
  },
  methods: {
    previewSolution: function (url) {
      for (let solution of this.solutions) {
        if (solution.url == url) {
          this.selectedSolution = solution;
        }
      }
    },
    pageMove: function (index) {
      var ctr = 0
      for (let solution of this.solutions) {
        if (ctr == index) {
          history.pushState(
            {},
            null,
            '/solution/' + solution.url
          )
          this.selectedSolution = solution
        }
        ctr++
      }
    }
  }
}
</script>

<style>
body {
  background-color: #f1f1f1;
}
.container-more {
  color: #FFFFFF !important;
}
</style>
<style scoped>
div#solution-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.solution-container {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  height: calc(100% - 140px);
  background: white;
  display: flex;
}
.square {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #008ED6;
  width: 10vw;
  height: 10vw;
}

.solutions-list {
  border-right: 1px solid rgba(13,13,13,.5);
  width: 15%;
}
ul.solution-links {
  margin-top: 200px;
  list-style-type: none;
  padding-left: 35%;
}
ul.solution-links li {
  height: 48px;
  text-align: right;
  padding-right: 24px;
  margin-right: -3px;
  border-right: 5px solid transparent;
}
ul.solution-links li a {
  color: #CFCFCF;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: bold;
  line-height: 48px;
  text-decoration: none;
}
ul.solution-links li.selected {
  border-right: 5px solid #008ED6;
}
ul.solution-links li.portfolio a {
  color: #f1b500;
}
ul.solution-links li.selected a {
  font-size: 16px;
  color: #008ED6;
}

.solution-content {
  display: flex;
  justify-content: space-around;
  width: calc(85% - 48px);
  overflow-y: auto;
  padding: 48px 24px;
}

.solution {
  flex-basis: 65%;
}
.solution-title {
  font-family: "Nanum Gothic";
  font-size: 35px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 24px;
  color: #008ED6;
}
.solution-body {
  font-family: "Nanum Gothic";
  font-size: 16px;
  line-height: 2;
}

.solution-images {
  flex-basis: 35%;
  display: flex;
  flex-direction: column;
}
.solution-images img {
  padding: 24px 0 0 24px;
  width: calc(100% - 24px);
}

div.cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: calc(1600px - 10%);
  margin-left: auto;
  width: 100%;
  height: 100%;
}

@media(max-width: 1440px) {
  .solutions-list {
    width: 20%;
  }
  .solution-content {
    width: 80%;
  }
  div.cover {
    height: 140px;
  }
}

@media(max-width: 1280px) {
  .solutions-list {
    width: 25%;
  }
  .solution-content {
    width: 75%;
    padding: 24px;
  }
  .solution {
    flex-basis: 55%;
  }
  .solution-images {
    flex-basis: 45%;
  }
  .solution-title {
    font-size: 30px;
    line-height: 36px;
  }
}

@media(max-width: 768px) {
  .solutions-list ul {
    padding-left: 24px;
  }
  .solution-container {
    bottom: 64px;
    height: calc(100% - 140px - 64px);
  }
  .square {
    display: none;
  }
  .solution-content {
    flex-direction: column;
    justify-content: normal;
  }
  .solution {
    flex-basis: auto;
  }
  .solution-images {
    flex-basis: auto;
  }
  .solution-images img {
    padding-left: 0;
    width: 100%;
  }
}

@media(max-width: 576px) {
  .solution-container {
    flex-direction: column;
  }
  .solutions-list {
    width: 100%;
  }
  .solutions-list ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 18px 0 12px;
  }
  .solutions-list ul li.portfolio {
    display: none;
  }
  .solutions-list ul li {
    border: 2px solid #CFCFCF;
    flex: 0 0 calc(50% - 36px);
    text-align: center;
    padding: 0;
    margin: 6px;
    height: 24px;
  }
  .solutions-list ul li.selected {
    border: 2px solid #008ED6;
  }
  ul.solution-links li a {
    display: block;
    line-height: 24px;
  }
  .solution-content {
    width: calc(100% - 48px);
  }
}

@media(max-width: 425px) {
  .solution-container {
    flex-direction: column;
  }
  .solution-title {
    font-family: "Nanum Gothic";
    font-size: 20px;
    font-weight: bold;
    line-height: 50px;
    margin-bottom: 24px;
    color: #008ED6;
  }
  .solution-body {
    font-family: "Nanum Gothic";
    font-size: 14px;
    line-height: 2;
  }
  ul.solution-links li a {
    color: #CFCFCF;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
  }
  ul.solution-links li.selected a {
    font-size: 12px;
    color: #008ED6;
  }
}
</style>

<style>
#plusfriend-chat-button {
  bottom: 12px;
}

@media(max-width: 1024px) {
  #plusfriend-chat-button {
    bottom: 64px;
  }
}
</style>
